import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { Biometric, BiometricType } from "../../../../models/entities/biometrics.model";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { Chart } from "primereact/chart";
import { BiometricsFormatter } from "../../../../services/biometrics/biometrics-formatter.service";
import {
	CHART_DATA,
	CHART_FILTER,
	CHART_TYPE,
} from "../../../../models/pages/biometrics/biometrics-charts.model";
import BiometricsChartsChartSummary from "./BiometricsChartsChartSummary";
import "chartjs-adapter-moment";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";

const BiometricsChartsChart = ({
	biometrics,
	filters,
	onDatasetsChange,
}: {
	biometrics: Map<string, Biometric[]>;
	filters: { type: CHART_TYPE; range: CHART_FILTER };
	onDatasetsChange: (e: CHART_DATA) => void;
}) => {
	const { t } = useTranslation("common");

	const [chartDatasets, setChartDatasets] = useState<CHART_DATA>();

	useEffect(() => {
		mapDatasets();
	}, [filters]);

	/* Chart configuration */

	const chartConfig = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			tooltip: {
				callbacks: {
					title: function (context: any) {
						return [];
					},
					label: function (context: any) {
						let label: string = ``;
						const date = Formatter.date(context.raw.x, {
							day: "2-digit",
							month: "2-digit",
							year: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
						});

						switch (filters.type) {
							case CHART_TYPE.BLOOD_PRESSURE:
								const values = biometrics
									.get(BiometricType.blood_pressure)
									?.find(
										(x) =>
											new Date(x.vitalTime).getTime() ===
											(context.raw.x as Date).getTime()
									);
								const formattedValue = BiometricsFormatter.formatBloodPressure(
									values?.entries.find(
										(x) => x.subType === BiometricType.blood_pressure_systolic
									)?.value,
									values?.entries.find(
										(x) => x.subType === BiometricType.blood_pressure_diastolic
									)?.value
								);
								label = `${formattedValue}; ${date}`;
								break;
							case CHART_TYPE.GLUCOSE:
								label = `${BiometricsFormatter.formatGlucose(context.raw.y)}; ${date}`;
								break;
							case CHART_TYPE.WEIGHT:
								label = `${BiometricsFormatter.formatWeight(context.raw.y)}; ${date}`;
								break;
							default:
								break;
						}
						return label;
					},
				},
			},
			legend: {
				position: "right",
				align: "start",
			},
		},
		scales: {
			x: {
				type: "time",
				time: {
					unit: "day",
					displayFormats: { day: "MM/DD/YY" },
				},
				ticks: {
					source: "labels",
					color: "#555555",
				},
				grid: {
					color: "#F2F2F0",
				},
				// offset: true
			},
			y: {
				offset: true,
				ticks: {
					color: "#555555",
				},
				grid: {
					color: "#F2F2F0",
				},
			},
		},
	};

	/* Chart data */

	const isWithinRange = (vitalTime: Date): boolean => {
		let value = false;
		const today: Date = new Date();

		switch (filters.range) {
			case CHART_FILTER.ALL:
				value = true;
				break;
			case CHART_FILTER.WEEK:
				if (Formatter.datesDaysDiffLocal(vitalTime, today) <= 7) {
					value = true;
				}
				break;
			case CHART_FILTER.MONTH:
				if (Formatter.datesDaysDiffLocal(vitalTime, today) <= 30) {
					value = true;
				}
				break;
			case CHART_FILTER.TRIMESTER:
				if (Formatter.datesDaysDiffLocal(vitalTime, today) <= 90) {
					value = true;
				}
				break;
			default:
				break;
		}

		return value;
	};

	const mapData = (type: BiometricType, subType?: BiometricType): { x: Date; y: number }[] => {
		const value: { x: Date; y: number }[] = [];

		const tmpData = biometrics.get(type);
		if (tmpData && tmpData.length > 0) {
			tmpData.forEach((item) => {
				if (subType) {
					item.entries.forEach((element) => {
						if (
							element.subType === subType &&
							isWithinRange(new Date(item.vitalTime))
						) {
							value.push({ x: new Date(item.vitalTime), y: element.value });
						}
					});
				} else if (isWithinRange(new Date(item.vitalTime))) {
					value.push({ x: new Date(item.vitalTime), y: item.entries[0]?.value });
				}
			});
		}

		return value;
	};

	const mapDatasets = (): void => {
		const data: CHART_DATA = {
			labels: [],
			datasets: [],
		};

		// Update datasets
		switch (filters.type) {
			case CHART_TYPE.GLUCOSE:
				data.datasets = [
					{
						label: t("PARTICIPANT.BIOMETRICS.CHARTS.TITLE_LEGEND_GLUCOSE"),
						data: mapData(BiometricType.glucose),
					},
				];
				if (data.datasets[0].data.length) {
					data.labels = [
						data.datasets[0].data[0].x,
						data.datasets[0].data[data.datasets[0].data.length - 1].x,
					];
				}
				break;
			case CHART_TYPE.BLOOD_PRESSURE:
				data.datasets = [
					{
						label: t(
							"PARTICIPANT.BIOMETRICS.CHARTS.TITLE_LEGEND_BLOOD_PRESSURE_SYSTOLIC"
						),
						data: mapData(
							BiometricType.blood_pressure,
							BiometricType.blood_pressure_systolic
						),
					},
					{
						label: t(
							"PARTICIPANT.BIOMETRICS.CHARTS.TITLE_LEGEND_BLOOD_PRESSURE_DIASTOLIC"
						),
						data: mapData(
							BiometricType.blood_pressure,
							BiometricType.blood_pressure_diastolic
						),
					},
				];
				if (data.datasets[0].data.length) {
					data.labels = [
						data.datasets[0].data[0].x,
						data.datasets[0].data[data.datasets[0].data.length - 1].x,
					];
				}
				break;
			case CHART_TYPE.WEIGHT:
				data.datasets = [
					{
						label: t("PARTICIPANT.BIOMETRICS.CHARTS.TITLE_LEGEND_WEIGHT"),
						data: mapData(BiometricType.weight),
					},
				];
				if (data.datasets[0].data.length) {
					data.labels = [
						data.datasets[0].data[0].x,
						data.datasets[0].data[data.datasets[0].data.length - 1].x,
					];
				}
				break;
			default:
				break;
		}

		// Update state
		setChartDatasets(data);
		onDatasetsChange(data);
	};

	return (
		<div className="biometrics-charts">
			{BiometricsHelper.emptyDataset(chartDatasets) ? (
				<>
					<div className="text-bold fs-5 mb-2">
						{t(
							filters.type === CHART_TYPE.GLUCOSE
								? "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_GLUCOSE"
								: filters.type === CHART_TYPE.WEIGHT
									? "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_WEIGHT"
									: "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_BLOOD_PRESSURE"
						)}
					</div>
					<div className="k-empty-message mb-3">
						<UiFieldMessage
							severity={FieldMessageSeverity.INFO}
							label="PARTICIPANT.BIOMETRICS.CHARTS.EMPTY_MESSAGE"
						/>
					</div>
				</>
			) : (
				<>
					{/* Chart info */}
					<BiometricsChartsChartSummary
						type={filters.type}
						chartData={chartDatasets as CHART_DATA}
						biometrics={biometrics}
					/>

					{/* Charts: Glucose / Blood pressure / Weight */}
					<div className="text-bold fs-5 mt-3">
						{t(
							filters.type === CHART_TYPE.GLUCOSE
								? "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_GLUCOSE"
								: filters.type === CHART_TYPE.WEIGHT
									? "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_WEIGHT"
									: "PARTICIPANT.BIOMETRICS.CHARTS.TITLE_CHART_BLOOD_PRESSURE"
						)}
					</div>
					<div className="charts">
						{chartDatasets && chartConfig && (
							<Chart
								type="scatter"
								data={chartDatasets}
								options={chartConfig}
								height="300px"
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
};
export default BiometricsChartsChart;
