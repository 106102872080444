import { useTranslation } from "react-i18next";
import UiButton from "../../../components/button/Button";
import { DetailsMode } from "../../../models/pages/participant-details.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import FormSection from "../../../components/form-section/FormSection.component";

const ParticipantDetailsCard = ({
	title,
	submitting,
	mode,
	enableEditing,
	showError,
	enableSubmit,
	onModeChange,
	onCancel,
	children,
}: {
	title: string;
	submitting: boolean;
	mode: DetailsMode;
	enableEditing: boolean;
	showError: boolean;
	enableSubmit: boolean;
	onModeChange: () => void;
	onCancel: () => void;
	children: React.ReactNode;
}) => {
	const { t } = useTranslation("common");

	return (
		<div className="pt-details-card">
			{/* Card: header */}
			<div className="details-header">{t(title)}</div>

			{/* Card: content */}
			<FormSection>{children}</FormSection>

			{/* Error messages */}
			{enableEditing && showError && (
				<div className="form-message">
					<UiFieldMessage
						severity={FieldMessageSeverity.DANGER}
						label="PARTICIPANT.DETAILS.SUBMIT_ERROR"
					/>
				</div>
			)}

			{enableEditing && (
				<div className="action-buttons">
					{mode === DetailsMode.READ ? (
						<UiButton
							label="UI_COMPONENTS.BUTTONS.UPDATE"
							className="p-button-rounded p-button-sm"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								onModeChange();
							}}
						/>
					) : (
						<>
							<UiButton
								label="UI_COMPONENTS.BUTTONS.CANCEL"
								className="p-button-outlined p-button-rounded p-button-sm"
								onClick={onCancel}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "UI_COMPONENTS.BUTTONS.SAVING"
										: "UI_COMPONENTS.BUTTONS.SAVE"
								}
								className="p-button-rounded p-button-sm"
								type="submit"
								loading={submitting}
								disabled={submitting || !enableSubmit}
							/>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default ParticipantDetailsCard;
