import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import {
	AppointmentModalMode,
	AppointmentModalProps,
	AppointmentModalStoredData,
} from "../../models/components/appointment-modal.model";
import AppointmentModalForm from "./AppointmentModalForm";
import AppointmentModalConfirmation from "./AppointmentModalConfirmation";

const AppointmentModal = ({ ...props }: AppointmentModalProps) => {
	const { t } = useTranslation("common");

	const [step, setStep] = useState<"FORM" | "CONFIRMATION">("FORM");
	const [sharedData, setSharedData] = useState<AppointmentModalStoredData | null>(null);

	return (
		<Dialog
			header={t(
				props.mode === AppointmentModalMode.SCHEDULE
					? "APPOINTMENTS.MODAL_APPOINTMENT.TITLE_SCHEDULE"
					: "APPOINTMENTS.MODAL_APPOINTMENT.TITLE_RESCHEDULE"
			)}
			visible={props.isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={() => {
				setStep("FORM");
				setSharedData(null);
				props.onCancel();
			}}
		>
			{step === "FORM" && (
				<AppointmentModalForm
					mode={props.mode}
					participant={props.participant!}
					coach={props.coach}
					appointment={props.appointment}
					appointmentTypes={props.appointmentTypes}
					sharedData={sharedData}
					onCancel={props.onCancel}
					onNext={(e: AppointmentModalStoredData) => {
						setSharedData(e);
						setStep("CONFIRMATION");
					}}
				/>
			)}

			{step === "CONFIRMATION" && (
				<AppointmentModalConfirmation
					mode={props.mode}
					participant={props.participant!}
					appointment={props.appointment}
					appointmentTypes={props.appointmentTypes}
					coach={props.coach}
					formData={sharedData?.formData!}
					onBack={() => setStep("FORM")}
					onConfirm={(e) => {
						props.onConfirm(e);
						setSharedData(null);
						setStep("FORM");
					}}
				/>
			)}
		</Dialog>
	);
};
export default AppointmentModal;
