import {
	Appointment,
	AppointmentParticipant,
	AppointmentType,
	AppointmentTypes,
} from "../entities/appointments.model";
import { List } from "../misc.model";

export interface AppointmentModalProps {
	isVisible: boolean;
	mode: AppointmentModalMode;
	participant: AppointmentParticipant | null;
	coach: AppointmentModalCoach;
	appointmentTypes: AppointmentTypes[];
	appointment: Appointment | null;
	onCancel: () => void;
	onConfirm: (e: Appointment) => void;
}

export enum AppointmentModalMode {
	SCHEDULE = "SCHEDULE",
	RESCHEDULE = "RESCHEDULE",
}

export interface AppointmentModalFormData {
	kannactId: string;
	appointmentType: AppointmentType | null;
	dateOn: Date | null;
	timezone: string | null;
	time: string | null;
}

export interface AppointmentModalCoach {
	calendarId: string;
	timezone: string;
}

export interface AppointmentModalStoredData {
	formData: AppointmentModalFormData | null;
	disabledDates: Date[];
	timeSlots: List;
}
