import { useState } from "react";
import UiButton from "../../../components/button/Button";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { Form } from "react-final-form";
import {
	ProcedureCreateForm,
	ProcedureStatus,
	ProcedureType,
} from "../../../models/procedures.model";
import { FormApi } from "final-form";
import UiInputDate from "../../../components/input-date/InputDate.component";
import UiSelect from "../../../components/select/Select.component";
import { Validations } from "../../../services/form/validations.service";
import UiInputTextarea from "../../../components/input-textarea/InputTextarea.component";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { Formatter } from "../../../services/formatter/formatter.service";
import FormSection from "../../../components/form-section/FormSection.component";

const ProcedureCreate = ({
	participantId,
	onClose,
}: {
	participantId: number;
	onClose: () => void;
}) => {
	const [message, setMessage] = useState<boolean>(false);
	const [formData, setFormData] = useState<ProcedureCreateForm>({
		dateOn: null,
		type: null,
		status: null,
		notes: null,
	});

	// Submit
	const handleSubmit = async (
		data: ProcedureCreateForm,
		form: FormApi<ProcedureCreateForm, ProcedureCreateForm>
	) => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.createProcedure({
				body: {
					participantId,
					dateOn: Formatter.dateUTCWithoutTime(formData.dateOn)?.toISOString()!,
					type: formData.type!,
					status: formData.status!,
					notes: formData.notes,
				},
			})
			.then((response) => onClose())
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<FormSection>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
								<UiInputDate
									id="dateOn"
									placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.dateOn"
									label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.dateOn"
									name="dateOn"
									onChange={(e) =>
										setFormData({
											...formData,
											dateOn: e.target.value as Date | null,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<UiSelect
									id="type"
									placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.type"
									label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.type"
									name="type"
									onChange={(e) =>
										setFormData({ ...formData, type: e.target.value })
									}
									validations={[Validations.required]}
									options={[
										{
											id: ProcedureType.GLUCOSE_TOLERANCE_TEST,
											label: `ENUMS.PROCEDURES_TYPES.${ProcedureType.GLUCOSE_TOLERANCE_TEST}`,
										},
									]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<UiSelect
									id="status"
									placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.status"
									label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.status"
									name="status"
									onChange={(e) =>
										setFormData({ ...formData, status: e.target.value })
									}
									validations={[Validations.required]}
									options={[
										{
											id: ProcedureStatus.COMPLETED,
											label: `ENUMS.PROCEDURES_STATUS.${ProcedureStatus.COMPLETED}`,
										},
										{
											id: ProcedureStatus.SCHEDULED,
											label: `ENUMS.PROCEDURES_STATUS.${ProcedureStatus.SCHEDULED}`,
										},
									]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<UiInputTextarea
									id="notes"
									placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.notes"
									label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.notes"
									name="notes"
									onChange={(e) =>
										setFormData({ ...formData, notes: e.target.value })
									}
									disabled={submitting}
								/>
							</div>
						</div>
					</FormSection>

					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.ADD_PROCEDURE.SUBMIT_ERROR"
							/>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							id="modalBtnCancel"
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							className="p-button-outlined p-button-rounded"
							type="button"
							onClick={onClose}
							disabled={submitting}
						></UiButton>
						<UiButton
							id="modalBtnSubmit"
							loading={submitting}
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							className="p-button-rounded"
							type="submit"
							onClick={handleSubmit}
							disabled={submitting || form.getState().invalid}
						></UiButton>
					</div>
				</form>
			)}
		/>
	);
};

export default ProcedureCreate;
