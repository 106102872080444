import { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { IoMdSend } from "react-icons/io";
import { FormApi } from "final-form";
import { AxiosError } from "axios";
import { BiMessageAltCheck } from "react-icons/bi";
import { useParticipantSms } from "./useParticipantSms";
import { useParticipantSmsMembers } from "./useParticipantSmsMembers";
import { useCreateParticipantSms } from "./useCreateParticipantSms";
import SmsMessage from "./SmsMessage";
import { ParticipantProfile } from "../../../../models/entities/participant.model";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import UiButton from "../../../../components/button/Button";
import { Validations } from "../../../../services/form/validations.service";
import { useMarkConversationAsRead } from "../care-messages/useMarkConversationAsRead";
import { useUnreadMessagesCounts } from "../care-messages/useUnreadMessagesCounts";
import UiInputTextarea from "../../../../components/input-textarea/InputTextarea.component";

const ParticipantSms = ({ participant }: { participant: ParticipantProfile }) => {
	const TWILIO_MAX_CHARACTERS_SMS_LENGTH = 1600;
	const createParticipantSms = useCreateParticipantSms();
	// The SMS list container (scroll to the bottom when receiving a new SMS)
	const messagesContainerRef = useRef<HTMLDivElement>(null);

	const participantSms = useParticipantSms(participant.id.toString());
	const participantSmsMmebers = useParticipantSmsMembers({ participant });

	const [formData, setFormData] = useState<{
		message: string | null;
		error: string | null;
	}>({
		message: null,
		error: null,
	});

	const unreadMessagesCounts = useUnreadMessagesCounts({
		participantId: participant.id,
	});

	useEffect(() => {
		if (participantSms.data && participantSms.data.length > 0) scrollToBottom();
	}, [participantSms.data]);

	const scrollToBottom = (): void =>
		messagesContainerRef.current?.scrollTo({
			top: messagesContainerRef.current.scrollHeight,
			behavior: "smooth",
		});

	const handleSubmit = async (
		_data: typeof formData,
		form: FormApi<typeof formData, typeof formData>
	) => {
		if (!formData.message) {
			return;
		}

		setFormData({ ...formData, error: null });

		try {
			await createParticipantSms.mutateAsync({
				participantId: participant.id.toString(),
				content: formData.message,
			});

			scrollToBottom();
			setFormData({ ...formData, message: null, error: null });
			form.resetFieldState("message");
		} catch (error) {
			const errorMessage =
				error instanceof AxiosError && error?.response?.status === 409
					? "PARTICIPANT.MESSAGES.SMS.CONVERSATION_INFO.INVALID_COACH_PHONE_SEND_MESSAGE_ERROR"
					: "PARTICIPANT.MESSAGES.SMS.CONVERSATION_INFO.GENERIC_SEND_MESSAGE_ERROR";

			setFormData({
				...formData,
				error: errorMessage,
			});
		}
	};

	const markConversationAsRead = useMarkConversationAsRead();

	const hasUnreadMessages = !!unreadMessagesCounts.data?.smsUnreadCount;

	return (
		<div className="sms-container">
			{/* Control messages: loading/error */}
			{(participantSms.isLoading || participantSms.isError) && (
				<div className="k-empty-message">
					{participantSms.isLoading && (
						<UiFieldMessage
							severity={FieldMessageSeverity.LOADING}
							label="PARTICIPANT.MESSAGES.SMS.EMPTY_MESSAGE_LOADING"
						/>
					)}
					{participantSms.isError && (
						<UiFieldMessage
							severity={FieldMessageSeverity.DANGER}
							label="PARTICIPANT.MESSAGES.SMS.EMPTY_MESSAGE_ERROR"
						/>
					)}
				</div>
			)}

			{/* Data */}
			{participantSms.data && (
				<div className="messages-wrapper">
					{/* Conversation: information */}
					<div className="messages-info-responsive">
						{/* Mark all the messages as read */}
						{hasUnreadMessages && (
							<UiButton
								label="PARTICIPANT.MESSAGES.SMS.CONVERSATION_INFO.MARK_ALL_READ"
								className="p-button-sm p-button-rounded mb-2"
								iconPos="left"
								disabled={markConversationAsRead.isLoading}
								onClick={() =>
									markConversationAsRead.mutate({
										participantId: participant.id,
										type: "sms",
									})
								}
							/>
						)}
					</div>

					{/* Conversation: chat */}
					<div className="messages-container">
						<div
							className="messages-history"
							ref={messagesContainerRef}
						>
							{participantSms.data.map((item) => (
								<SmsMessage
									key={item.sid}
									message={item}
									members={participantSmsMmebers.data}
								/>
							))}
						</div>

						<Form
							onSubmit={handleSubmit}
							initialValues={formData}
							render={({ handleSubmit, submitting }) => (
								<form onSubmit={handleSubmit}>
									<div className="messages-write">
										<UiInputTextarea
											id="message"
											placeholder="PARTICIPANT.MESSAGES.SMS.CONVERSATION_MESSAGES.PLACEHOLDER"
											name="message"
											autoResize={true}
											onChange={(e) =>
												setFormData({
													...formData,
													message: e.target.value,
												})
											}
											maxLength={TWILIO_MAX_CHARACTERS_SMS_LENGTH}
											validations={[Validations.required]}
											disabled={submitting}
										/>
										<UiButton
											label={
												submitting
													? "PARTICIPANT.MESSAGES.SMS.CONVERSATION_MESSAGES.SUBMITTING"
													: "PARTICIPANT.MESSAGES.SMS.CONVERSATION_MESSAGES.SUBMIT"
											}
											className="p-button-rounded"
											type="submit"
											disabled={submitting}
										/>
									</div>
								</form>
							)}
						/>

						{formData.error && (
							<div className="k-empty-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label={formData.error}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
export default ParticipantSms;
