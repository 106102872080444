import { Reminder } from "../entities/reminder.model";
import { UiFieldMessageProps } from "./field-message.model";

export enum ModalReminderMode {
	CREATE = "CREATE",
	DELETE = "DELETE",
	DETAILS = "DETAILS",
	SNOOZE = "SNOOZE",
}

export interface UiModalReminderProps {
	mode: ModalReminderMode;
	entity: Partial<Reminder> | null;
	isVisible: boolean;
	onComplete: (refresh: boolean, reminder: Reminder | null) => void;
	onCancel: () => void;
}

export interface ModalReminderFormProps extends Omit<UiModalReminderProps, "isVisible"> {}

export interface ModalReminderDeleteProps {
	entity: Reminder;
	onComplete: () => void;
	onCancel: () => void;
}

export type ModalReminderData = {
	// Control variables
	formMessage?: UiFieldMessageProps;
	mode: ModalReminderMode;
	// Form fields
	kannactId: string | null;
	reason: string | null;
	dateOn: Date | null;
};
