import { FieldError, FieldValidation } from "../../models/form.model";

/**
 * Masks: regexp
 * @description Export a series of predefined regexp
 */
const masks: { [x: string]: RegExp } = {
	email: /^[\w-.+]+@[\w-]+\.+[\w-]{2,4}$/,
	// email2: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	any: /.*/,
	numbers: /^[0-9]*$/,
	letters: /^[a-zA-Z]*$/,
};

/**
 * Validator: letters
 * @description Allow only letters
 */
const letters: FieldValidation = (value: any): FieldError | undefined =>
	value && value.toString().trim() !== "" && !masks.letters.test(value)
		? { letters: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.letters" }
		: undefined;

/**
 * Validator: numbers
 * @description Allow only numbers
 */
const numbers: FieldValidation = (value: any): FieldError | undefined =>
	value && value.toString().trim() !== "" && !masks.numbers.test(value)
		? { numbers: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.numbers" }
		: undefined;

/**
 * Validator: email
 * @description Allow only emails that match the correct pattern
 */
const email: FieldValidation = (value: any): FieldError | undefined =>
	!masks.email.test(value)
		? { email: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.email" }
		: undefined;

/**
 * Validator: required
 * @description The field must have a value
 */
const required: FieldValidation = (value: any): FieldError | undefined =>
	!value && value !== false && value !== 0
		? { required: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.required" }
		: undefined;

const minLength =
	(min: number) =>
	(value: any): FieldError | undefined =>
		(value || value === 0) && value.toString().length >= min
			? undefined
			: {
					minLength: {
						key: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.minLength",
						params: { value: min },
					},
				};

const maxLength =
	(max: number) =>
	(value: any): FieldError | undefined =>
		(value || value === 0) && value.toString().length <= max
			? undefined
			: {
					maxLength: {
						key: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.maxLength",
						params: { value: max },
					},
				};

const numberMaxDigits =
	(maxDigits: number, maxDecimals: number) =>
	(value: any): FieldError | undefined =>
		(value || value === 0) &&
		(value.toString().split(".")[0].length > maxDigits ||
			value.toString().split(".")[1]?.length > maxDecimals)
			? {
					numberMaxDigits: {
						key: "UI_COMPONENTS.FIELD_MESSAGE.VALIDATIONS.numberMaxDigits",
						params: { digits: maxDigits, decimals: maxDecimals },
					},
				}
			: undefined;

// Applies the validators and return all the different errors
const composeValidators =
	(validators?: any[]) =>
	(value: any): FieldError | undefined =>
		validators?.reduce(
			(error, validator) => (error ? { ...error, ...validator(value) } : validator(value)),
			undefined
		) || undefined;

// List of available validations
export const Validations = {
	composeValidators,
	required,
	email,
	numbers,
	letters,
	minLength,
	maxLength,
	numberMaxDigits,
};
