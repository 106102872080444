import { AppointmentsService } from "../../../services/appointments/appointments.service";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
} from "../../components/table/table-template.model";
import { Appointment, AppointmentTypes } from "./../../entities/appointments.model";
import { AppointmentRow } from "./appointments.model";

export type UpcomingAppointment = Omit<
	AppointmentRow,
	| "name"
	| "kannactId"
	| "coachId"
	| "phone"
	| "typeTemplate"
	| "kannactIdTemplate"
	| "phoneTemplate"
> & { entity: Appointment };
export class UpcomingAppointmentRow implements UpcomingAppointment {
	id: string;
	type: string;
	datetime: string; // Date
	duration: string;
	actionsTemplate: TableTemplateButtons;
	entity: Appointment;

	constructor(
		params: Appointment,
		appointmentTypes: AppointmentTypes[],
		rescheduleFn: (e: TableTemplateButtonsEvent) => any,
		cancelFn: (e: TableTemplateButtonsEvent) => any
	) {
		this.id = params.id;
		this.type = params.type;
		this.datetime = AppointmentsService.templateDatetime(params.datetime)!;
		this.duration = AppointmentsService.templateDuration(params.type, appointmentTypes);
		this.actionsTemplate = AppointmentsService.templateActions(rescheduleFn, cancelFn);
		this.entity = params;
	}
}
