import * as DataStorageService from "../../services/session/session.service";
import { StoredCriteria } from "../../models/stored-criteria.model";
import { ITablePagination } from "../../models/components/table/table-pagination.model";
import {
	ITableFilter,
	ITableHeaderFilter,
	TableFilterType,
} from "../../models/components/table/table-filter.model";
import { HelperService } from "../helpers/helper.service";

enum KEYS {
	"settings-users" = "settings-users",
	"users-at-risk" = "users-at-risk",
	"dashboard-voicemails" = "dashboard-voicemails",
	"dashboard-calls" = "dashboard-calls",
	"call-center-calls" = "call-center-calls",
	"call-center-voicemails" = "call-center-voicemails",
	"participants-dashboard" = "participants-dashboard",
	"participants-appointments" = "participants-appointments",
	"participants-reminders" = "participants-reminders",
	"participants-messages" = "participants-messages",
}
const KEY_PREFIX = "criteria";
const formatKey = (key: string) =>
	`${KEY_PREFIX}/${key}/${HelperService.isAdminViewMode() ? "admin" : "coach"}`;

const get = (key: string): StoredCriteria | undefined => DataStorageService.getItem(formatKey(key));
const getPagination = (key: string): ITablePagination | undefined => {
	const pagination = DataStorageService.getItem(formatKey(key))?.pagination;
	if (pagination?.filters) pagination.filters = unstringify(pagination.filters);
	return pagination;
};
const getFilters = (key: string): ITableHeaderFilter | undefined => {
	const filters = DataStorageService.getItem(formatKey(key))?.filters;
	if (filters?.filters) filters.filters = unstringify(filters.filters);
	return filters;
};

const set = (key: string, pagination: ITablePagination, filters?: ITableHeaderFilter) =>
	DataStorageService.setItem(formatKey(key), { pagination, filters });

const setPagination = (key: string, pagination: ITablePagination) => {
	const savedData: StoredCriteria = DataStorageService.getItem(formatKey(key));
	DataStorageService.setItem(formatKey(key), {
		pagination,
		filters: savedData?.filters,
	});
};
const setFilters = (key: string, filters?: ITableHeaderFilter) => {
	const savedData: StoredCriteria = DataStorageService.getItem(formatKey(key));
	DataStorageService.setItem(formatKey(key), {
		pagination: savedData?.pagination,
		filters,
	});
};

const deleteAll = () => {
	for (const key in KEYS) {
		if (Object.prototype.hasOwnProperty.call(KEYS, key) && get(key)) {
			DataStorageService.deleteItem(formatKey(key));
		}
	}
};

const unstringify = (data?: ITableFilter[]) => {
	let result = data;
	if (data?.length) {
		result = result?.map((x) => {
			if (x.value) {
				switch (x.type) {
					case TableFilterType.DATE_RANGE:
						const value = [];
						if (x.value[0]) value.push(new Date(x.value[0]));
						if (x.value[1]) value.push(new Date(x.value[1]));
						x.value = value || null;
						break;
					case TableFilterType.DATE_NAVIGATOR:
						if (x.value) x.value = new Date(x.value);
						break;
					default:
						break;
				}
			}
			return x;
		});
	}
	return result;
};

export const StoredCriteriaService = {
	KEYS,
	get,
	getPagination,
	getFilters,
	set,
	setPagination,
	setFilters,
	deleteAll,
};
