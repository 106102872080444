import { useTranslation } from "react-i18next";
import UiButton from "../button/Button";
import {
	AppointmentModalCoach,
	AppointmentModalFormData,
	AppointmentModalMode,
} from "../../models/components/appointment-modal.model";
import {
	Appointment,
	AppointmentParticipant,
	AppointmentTypes,
} from "../../models/entities/appointments.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../models/components/field-message.model";
import { useState } from "react";
import { Timezone } from "../../models/entities/participant.model";

const AppointmentModalConfirmation = ({
	mode,
	participant,
	appointment,
	appointmentTypes,
	coach,
	formData,
	onBack,
	onConfirm,
}: {
	mode: AppointmentModalMode;
	participant: AppointmentParticipant;
	appointment: Appointment | null;
	appointmentTypes: AppointmentTypes[];
	coach: AppointmentModalCoach;
	formData: AppointmentModalFormData;
	onBack: () => void;
	onConfirm: (e: Appointment) => void;
}) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<{
		submitting: boolean;
		errorMsg: string | null;
	}>({
		submitting: false,
		errorMsg: null,
	});

	const formatAppointmentType = () => {
		const type = appointmentTypes.find((x) => x.type === formData.appointmentType);
		const result = `${t(
			`ENUMS.APPOINTMENTS_TYPE.${formData.appointmentType}`
		)} (${type?.durationInMinutes} min + ${type?.totalPaddingInMinutes} buffer)`;
		return result;
	};

	const handleSubmit = async () => {
		// Re/Schedule appointment
		if (mode === AppointmentModalMode.SCHEDULE) {
			if (participant.timezone === Timezone.NA) {
				// Update the participant timezone
				await updatePtTimezone();
			}
			schedule();
		} else {
			reschedule();
		}
	};

	const schedule = async () => {
		// Loading state
		setMessage({ submitting: true, errorMsg: null });

		// Request
		await EndpointsService.appointments
			.createAppointment({
				body: {
					coachId: coach.calendarId,
					type: formData.appointmentType!,
					datetime: new Date(formData.time!).toISOString(),
					participant: {
						...participant,
						timezone: formData.timezone!,
						dateOfBirth: participant.dateOfBirth
							? new Date(participant.dateOfBirth).toISOString()
							: undefined,
					},
				},
			})
			.then((response) => {
				setMessage({ submitting: false, errorMsg: null });
				onConfirm(response);
			})
			.catch((error: AxiosError) => {
				switch (error.response?.status) {
					case 400:
						setMessage({
							submitting: false,
							errorMsg: "UI_COMPONENTS.FIELD_MESSAGE.HTTP.400",
						});
						break;
					case 404:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.SCHEDULE_404",
						});
						break;
					case 409:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.SCHEDULE_409",
						});
						break;
					default:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.SCHEDULE_500",
						});
						break;
				}
			});
	};

	const reschedule = async () => {
		// Loading state
		setMessage({ submitting: true, errorMsg: null });

		// Request
		await EndpointsService.appointments
			.updateAppointment({
				body: {
					appointmentId: appointment?.id!,
					datetime: new Date(formData.time!).toISOString(),
					timezone: formData.timezone!,
				},
			})
			.then((response) => {
				setMessage({ submitting: false, errorMsg: null });
				onConfirm(response);
			})
			.catch((error: AxiosError) => {
				switch (error.response?.status) {
					case 400:
						setMessage({
							submitting: false,
							errorMsg: "UI_COMPONENTS.FIELD_MESSAGE.HTTP.400",
						});
						break;
					case 404:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.RESCHEDULE_404",
						});
						break;
					case 409:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.SCHEDULE_409",
						});
						break;
					case 410:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.RESCHEDULE_410",
						});
						break;
					default:
						setMessage({
							submitting: false,
							errorMsg: "APPOINTMENTS.HTTP_MESSAGES.RESCHEDULE_500",
						});
						break;
				}
			});
	};

	const updatePtTimezone = async () => {
		setMessage({ submitting: true, errorMsg: null });
		await EndpointsService.dataRetriever.updateProfileContactInfo({
			body: {
				participantId: participant.id!,
				timezone: formData.timezone!,
			},
		});
		setMessage({ submitting: false, errorMsg: null });
	};

	return (
		<>
			<ul className="appointments-summary appointment-modal-confirmation">
				{/* Participant Kannact ID */}
				<li className="summary-item">
					<span className="item-desc">
						{t("APPOINTMENTS.MODAL_APPOINTMENT.KANNACT_ID")}
					</span>
					<span className="item-value">{participant.kannactId ?? Formatter.NO_DATA}</span>
				</li>

				{/* Participant name */}
				<li className="summary-item">
					<span className="item-desc">
						{t("APPOINTMENTS.MODAL_APPOINTMENT.PARTICIPANT")}
					</span>
					<span className="item-value">
						{Formatter.fullName(participant.firstName, participant.lastName)}
					</span>
				</li>

				{/* Appointment type */}
				<li className="summary-item">
					<span className="item-desc">
						{t("APPOINTMENTS.MODAL_APPOINTMENT.APPOINTMENT_TYPE")}
					</span>
					<span className="item-value">{formatAppointmentType()}</span>
				</li>

				{/* Appointment date */}
				<li className="summary-item">
					<span className="item-desc">
						{t("APPOINTMENTS.MODAL_APPOINTMENT.APPOINTMENT_DATE")}
					</span>
					<span className="item-value text-bold">
						{Formatter.date(formData.dateOn!, {
							day: "2-digit",
							month: "long",
							year: "numeric",
							weekday: "long",
						})}
					</span>
				</li>

				{/* Appointment time */}
				<li className="summary-item">
					<span className="item-desc">
						{t("APPOINTMENTS.MODAL_APPOINTMENT.APPOINTMENT_TIME")}
					</span>
					<span className="item-value text-bold">
						{/* Time: pt timezone / coach timezone  */}
						{`${Formatter.dateISOToString(formData.time!, {
							hour: "2-digit",
							minute: "2-digit",
							hour12: true,
							timeZoneName: "shortGeneric",
							timeZone: formData.timezone!,
						})} / ${Formatter.dateISOToString(formData.time!, {
							hour: "2-digit",
							minute: "2-digit",
							hour12: true,
							timeZoneName: "shortGeneric",
						})} `}
					</span>
				</li>
			</ul>

			{/* Warning message */}
			{/* <p></p> */}

			{/* Information message */}
			<p className="mt-4">{t("APPOINTMENTS.MODAL_APPOINTMENT.MSG_INFO2")}</p>

			{/* Actions: Next (confirmation) */}
			<div
				className="action-buttons appointment-modal-confirmation__action-buttons"
			>
				<UiButton
					id="modalBackBtn"
					label="UI_COMPONENTS.BUTTONS.BACK"
					className="p-button-outlined p-button-rounded"
					type="button"
					onClick={onBack}
					disabled={message.submitting}
				/>
				<UiButton
					id="modalConfirmBtn"
					label="UI_COMPONENTS.BUTTONS.CONFIRM"
					className="p-button-rounded"
					type="submit"
					onClick={handleSubmit}
					disabled={message.submitting}
					loading={message.submitting}
				/>

				{/* Messages: error */}
				{message.errorMsg && (
					<div className="mt-3 d-flex justify-content-end">
						<UiFieldMessage
							severity={FieldMessageSeverity.DANGER}
							label={message.errorMsg}
						/>
					</div>
				)}
			</div>
		</>
	);
};
export default AppointmentModalConfirmation;
