import { Source } from "./source.model";

export const BiometricUnit = {
	hemoglobinA1c: "%",
	bloodPressure: "mmHg",
	glucose: "mg/dL",
	weight: "lbs",
	heightFeet: "'",
	heightInches: '"',
	cholesterol: "mg/dL",
	triglycerides: "mg/dL",
	kidneyGfr: "mL/min/1.73 m²",
};

export enum BiometricType {
	hemoglobin_a1c = "A1C",
	blood_pressure_diastolic = "BLOOD_PRESSURE_DIASTOLIC",
	blood_pressure_systolic = "BLOOD_PRESSURE_SYSTOLIC",
	blood_pressure = "BLOOD_PRESSURE",
	height = "HEIGHT",
	weight = "WEIGHT",
	cholesterol = "CHOLESTEROL",
	cholesterol_hdl = "CHOLESTEROL_HDL",
	cholesterol_ldl = "CHOLESTEROL_LDL",
	cholesterol_total = "CHOLESTEROL_TOTAL",
	triglycerides = "TRIGLYCERIDES",
	kidney_gfr = "KIDNEY_GFR",
	glucose = "GLUCOSE",
}

export interface BiometricEntry {
	biometricId: number;
	value: number;
	unit: string;
	subType: string;
}

export interface Biometric {
	deviceId: number;
	participantId: number;
	isManual: boolean;
	source: Source;
	notes: string | null;
	isFasting: boolean;
	isPreMeal: boolean;
	type: BiometricType;
	entries: BiometricEntry[];
	estimated: boolean;
	vitalTime: string; // Date
	deleted: boolean;
	createdOn: string; // Date
	updatedOn: string; // Date
	deletedOn: string | null; // Date
}
