import { BiometricsLatest } from "../../endpoints/biometrics-endpoint.model";
import { BiometricType, Biometric } from "../../entities/biometrics.model";
import { Goal } from "../../entities/goal.model";
import {
	BiometricDateOnTemplate,
	BiometricNotesTemplate,
	BiometricSourceTemplate,
	BiometricValueTemplate,
} from "./biometrics-templates.model";

// Component modes
export enum BiometricsModalMode {
	CREATE = "CREATE",
	READ = "READ",
	DELETE = "DELETE",
}

// Component props
export type BiometricsModalProps = {
	mode: BiometricsModalMode;
	participantId: number;
	goals: Goal[];
	biometrics: Map<string, Biometric[]>;
	latestBiometric: BiometricsLatest | null;
	isVisible: boolean;
	closeModal: () => void;
};

// Biometric data
export interface IBiometricData {
	hemoglobinA1c: number | null;
	bloodPressureSystolic: number | null;
	bloodPressureDiastolic: number | null;
	weight: number | null;
	heightFeet: number | null;
	heightInches: number | null;
	cholesterolHdl: number | null;
	cholesterolLdl: number | null;
	cholesterolTotal: number | null;
	triglycerides: number | null;
	kidneyGfr: number | null;
	dateOn: Date | null;
	estimated: boolean | null;
	glucoseValue: number | null;
	glucoseContext: "PREMEAL" | "POSTMEAL" | "FASTING" | "UNKNOWN" | null;
}

export class BiometricData implements IBiometricData {
	hemoglobinA1c: number | null;
	bloodPressureSystolic: number | null;
	bloodPressureDiastolic: number | null;
	weight: number | null;
	heightFeet: number | null;
	heightInches: number | null;
	cholesterolHdl: number | null;
	cholesterolLdl: number | null;
	cholesterolTotal: number | null;
	triglycerides: number | null;
	kidneyGfr: number | null;
	dateOn: Date | null;
	estimated: boolean | null;
	glucoseValue: number | null;
	glucoseContext: "PREMEAL" | "POSTMEAL" | "FASTING" | "UNKNOWN" | null;

	constructor(params?: IBiometricData) {
		this.hemoglobinA1c = params?.hemoglobinA1c ?? null;
		this.bloodPressureSystolic = params?.bloodPressureSystolic ?? null;
		this.bloodPressureDiastolic = params?.bloodPressureDiastolic ?? null;
		this.weight = params?.weight ?? null;
		this.heightFeet = params?.heightFeet ?? null;
		this.heightInches = params?.heightInches ?? null;
		this.cholesterolHdl = params?.cholesterolHdl ?? null;
		this.cholesterolLdl = params?.cholesterolLdl ?? null;
		this.cholesterolTotal = params?.cholesterolTotal ?? null;
		this.triglycerides = params?.triglycerides ?? null;
		this.kidneyGfr = params?.kidneyGfr ?? null;
		this.dateOn = params?.dateOn ?? null;
		this.estimated = params?.estimated ?? null;
		this.glucoseValue = params?.glucoseValue ?? null;
		this.glucoseContext = params?.glucoseContext ?? null;
	}
}

// Table rows
export interface BiometricRow {
	ids: number[] | null;
	type: BiometricType;
	subTypes: BiometricType[];
	measurement: string;
	value: BiometricValueTemplate;
	estimated: boolean;
	goal?: Goal;
	dateOn: BiometricDateOnTemplate;
	source: BiometricSourceTemplate;
	notes: BiometricNotesTemplate;
	editing: boolean;
}
