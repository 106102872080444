export interface DeviceSummaryFormData {
	date: DeviceSummaryRange | null;
	dateRange: Date[] | null;
	email: string | null;
}

export enum DeviceSummaryRange {
	DAYS7 = "DAYS7",
	DAYS30 = "DAYS30",
	DAYS90 = "DAYS90",
	CUSTOM = "CUSTOM",
}
