import { getSessionStorageUser } from "../services/session/auth.service";

export enum AuthRoles {
	ADMIN = 1,
	COACH = 2,
	PARTICIPANT = 3,
	COACH_COORDINATOR = 4,
	CLIENT = 5,
	PROVIDER = 6,
}

const admins: number[] = [AuthRoles.ADMIN, AuthRoles.COACH_COORDINATOR, AuthRoles.PROVIDER];

const isAdmin = (roleId?: number): boolean => {
	let admin = false;
	const loggedUserRole = getSessionStorageUser()?.role;
	if (
		// Use the specified role
		(roleId && admins.indexOf(roleId) !== -1) ||
		// Role not specified, use the logged user role
		(!roleId && loggedUserRole && admins.indexOf(loggedUserRole) !== -1)
	) {
		admin = true;
	}
	return admin;
};

export const Roles = { isAdmin, admins };
