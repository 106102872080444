import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useProvidersInfo = () => {
	const queryClient = useQueryClient();
	const queryKey = ["providers-info"];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getProvidersInfo();
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
