import { useTranslation } from "react-i18next";
import { useState } from "react";
import ParticipantDetailsCard from "./ParticipantDetailsCard";
import { DetailsMode, PtGeneralInfoForm } from "../../../models/pages/participant-details.model";
import {
	Gender,
	PreferredLanguage,
	PreferredPronouns,
	ParticipantProfile,
} from "../../../models/entities/participant.model";
import { Form } from "react-final-form";
import UiInputText from "../../../components/input-text/InputText.component";
import { Validations } from "../../../services/form/validations.service";
import ParticipantDetailsField from "./ParticipantDetailsField";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiSelect from "../../../components/select/Select.component";
import UiInputDate from "../../../components/input-date/InputDate.component";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { FormApi } from "final-form";
import { ListOption } from "../../../models/misc.model";

const ParticipantDetailsGeneralInfo = ({
	participant,
	onUpdate,
}: {
	participant: ParticipantProfile;
	onUpdate: (updatedParticipant: ParticipantProfile) => void;
}) => {
	const { t } = useTranslation("common");

	const [mode, setMode] = useState<DetailsMode>(DetailsMode.READ);
	const [showError, setShowError] = useState<boolean>(false);
	const [formData, setFormData] = useState<PtGeneralInfoForm>(new PtGeneralInfoForm(participant));

	const setRequest = () => {
		const result: { [x: string]: any } = {};

		for (const key in formData) {
			if (Object.hasOwn(formData, key)) {
				if (
					key === "dob" &&
					formData.dob &&
					formData.dob.toISOString() !== new Date(participant.dob).toISOString()
				) {
					result[key as keyof PtGeneralInfoForm] = Formatter.dateUTCWithoutTime(
						formData.dob
					)?.toISOString();
				} else if (
					key !== "dob" &&
					formData[key as keyof PtGeneralInfoForm] !==
						participant[key as keyof ParticipantProfile]
				) {
					result[key as keyof PtGeneralInfoForm] =
						formData[key as keyof PtGeneralInfoForm];
				}
			}
		}

		return result;
	};

	const handleSubmit = async (
		data: PtGeneralInfoForm,
		form: FormApi<PtGeneralInfoForm, PtGeneralInfoForm>
	) => {
		setShowError(false);

		// Set request
		const body = setRequest();

		await EndpointsService.dataRetriever
			.updateProfileGeneralInfo({
				body: {
					participantId: participant.id,
					...body,
				},
			})
			.then((response) => {
				// Update form state
				form.reset();

				// Update participant state
				onUpdate(response);

				// Update mode
				setMode(DetailsMode.READ);
			})
			.catch((error) => setShowError(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<ParticipantDetailsCard
						title="PARTICIPANT.DETAILS.GENERAL_INFO.TITLE"
						submitting={submitting}
						mode={mode}
						enableEditing={true}
						showError={showError}
						enableSubmit={!!Object.keys(setRequest())}
						onCancel={() => {
							form.reset();
							setShowError(false);
							setFormData(new PtGeneralInfoForm(participant));
							setMode(DetailsMode.READ);
						}}
						onModeChange={() => setMode(DetailsMode.UPDATE)}
					>
						<div className="row">
							{/* First name */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.firstName"
										value={participant.firstName}
									/>
								) : (
									<UiInputText
										id="firstName"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.firstName"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.firstName"
										name="firstName"
										onChange={(e) =>
											setFormData({ ...formData, firstName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Last name */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.lastName"
										value={participant.lastName}
									/>
								) : (
									<UiInputText
										id="lastName"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.lastName"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.lastName"
										name="lastName"
										onChange={(e) =>
											setFormData({ ...formData, lastName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Preferred name */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.preferredName"
										value={participant.preferredName}
									/>
								) : (
									<UiInputText
										id="preferredName"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.preferredName"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.preferredName"
										name="preferredName"
										onChange={(e) =>
											setFormData({
												...formData,
												preferredName: e.target.value,
											})
										}
										disabled={submitting}
									/>
								)}
							</div>
						</div>

						<div className="row">
							{/* Gender */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.gender"
										value={
											participant.gender
												? t(Formatter.gender(participant.gender))
												: null
										}
									/>
								) : (
									<UiSelect
										id="gender"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.gender"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.gender"
										name="gender"
										onChange={(e) =>
											setFormData({ ...formData, gender: e.target.value })
										}
										options={[
											{ id: Gender.F, label: `ENUMS.GENDER.${Gender.F}` },
											{ id: Gender.M, label: `ENUMS.GENDER.${Gender.M}` },
											{
												id: Gender.OTHER,
												label: `ENUMS.GENDER.${Gender.OTHER}`,
											},
										]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Pronouns */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.pronouns"
										value={
											participant.pronouns
												? t(`ENUMS.PRONOUNS.${participant.pronouns}`)
												: null
										}
									/>
								) : (
									<UiSelect
										id="pronouns"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.pronouns"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.pronouns"
										name="pronouns"
										onChange={(e) =>
											setFormData({ ...formData, pronouns: e.target.value })
										}
										options={Object.keys(PreferredPronouns).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.PRONOUNS.${x}`,
												})
										)}
										disabled={submitting}
									/>
								)}
							</div>
						</div>

						<div className="row">
							{/* Date of birth */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.dob"
										value={Formatter.dateISOToString(participant.dob, {
											day: "numeric",
											month: "short",
											year: "numeric",
											timeZone: "UTC",
										})}
									/>
								) : (
									<UiInputDate
										id="dob"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.dob"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.dob"
										name="dob"
										onChange={(e) =>
											setFormData({
												...formData,
												dob: e.target.value as Date,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Age */}
							<div className="col-12 col-md-4">
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.age"
									value={
										participant.dob
											? Formatter.calculateAge(participant.dob)
											: null
									}
								/>
							</div>
						</div>

						<div className="row">
							{/* Preferred language */}
							<div className="col-12 col-md-4">
								{mode === DetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.language"
										value={
											participant.language
												? t(`ENUMS.LANGUAGE.${participant.language}`)
												: null
										}
									/>
								) : (
									<UiSelect
										id="language"
										placeholder="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.language"
										label="PARTICIPANT.DETAILS.GENERAL_INFO.FORM_FIELDS.language"
										name="language"
										onChange={(e) =>
											setFormData({ ...formData, language: e.target.value })
										}
										options={Object.keys(PreferredLanguage).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.LANGUAGE.${x}`,
												})
										)}
										disabled={submitting}
									/>
								)}
							</div>
						</div>
					</ParticipantDetailsCard>
				</form>
			)}
		/>
	);
};

export default ParticipantDetailsGeneralInfo;
