import { useState } from "react";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { useAppointmentsCoaches } from "../../../hooks/useAppointmentsCoaches";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { useAppointmentsTypes } from "../../../hooks/useAppointmentsTypes";
import UiButton from "../../../components/button/Button";
import AppointmentModal from "../../../components/appointments/AppointmentModal";
import { AppointmentModalMode } from "../../../models/components/appointment-modal.model";
import { PreferredLanguage, Timezone } from "../../../models/entities/participant.model";

const ParticipantProfileAppointment = ({
	participantContext,
}: {
	participantContext: ParticipantContextProps;
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const phoneNumber = participantContext.participant?.phones?.find((p) => p.isPrimary);
	const coaches = useAppointmentsCoaches();
	const coach = coaches.data?.find(
		(x) => x.gladstoneId === getSessionStorageUser()!.id?.toString()
	);
	const appointmentTypes = useAppointmentsTypes();
	const participant = participantContext.participant!;

	return (
		<>
			<UiButton
				id="modalAppointmentBtn"
				label="APPOINTMENTS.MODAL_APPOINTMENT.TITLE_SCHEDULE"
				type="button"
				className="p-button-rounded p-button-sm"
				onClick={() => setShowModal(true)}
				disabled={
					coaches.isLoading ||
					coaches.isError ||
					appointmentTypes.isLoading ||
					appointmentTypes.isError
				}
			/>

			<AppointmentModal
				mode={AppointmentModalMode.SCHEDULE}
				isVisible={showModal}
				participant={{
					id: participant.id,
					kannactId: participant.kannactId,
					firstName: participant.firstName,
					lastName: participant.lastName,
					institution: participant.institution,
					dateOfBirth: participant.dob,
					timezone: participant.timezone ?? Timezone.NA,
					email: participant.emails?.length
						? participant.emails.find((p) => p.isPrimary)!.emailAddress
						: "",
					phone: `${phoneNumber?.countryCode ?? ""}${phoneNumber?.phoneNumber ?? ""}`,
					isSpanish: participant.language === PreferredLanguage.SPANISH,
				}}
				coach={{
					calendarId: coach?.id ?? "",
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				}}
				appointmentTypes={appointmentTypes.data ?? []}
				appointment={null}
				onCancel={() => setShowModal(false)}
				onConfirm={(e) => {
					setShowModal(false);
					if (
						e.participant.timezone &&
						(participant.timezone === Timezone.NA || !participant.timezone)
					) {
						participantContext.onRefresh();
					}
				}}
			/>
		</>
	);
};
export default ParticipantProfileAppointment;
