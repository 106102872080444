import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import {
	BiometricData,
	IBiometricData,
} from "../../../models/pages/biometrics/biometrics-modal.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { BiometricsHelper } from "../../../services/biometrics/biometrics-helper.service";
import { useState } from "react";
import UiButton from "../../../components/button/Button";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { Form } from "react-final-form";
import FormSection from "../../../components/form-section/FormSection.component";
import UiInputDate from "../../../components/input-date/InputDate.component";
import { Validations } from "../../../services/form/validations.service";
import UiInputCheckbox from "../../../components/input-checkbox/InputCheckbox.component";
import UiInputNumber from "../../../components/input-number/InputNumber.component";
import { BiometricUnit } from "../../../models/entities/biometrics.model";
import UiSelect from "../../../components/select/Select.component";

const BiometricsCreate = ({
	isVisible,
	participantId,
	onComplete,
	onClose,
}: {
	isVisible: boolean;
	participantId: number;
	onComplete: () => void;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState<IBiometricData>(new BiometricData());
	const [message, setMessage] = useState<string | null>(null);

	const isValid = (): boolean => {
		/* 
		  TODO: Needs improvement
			- When blood pressure is provided both inputs are required
			- When height is provided both inputs are required
			- Cholesterol is ok with only one input
			- Glucose is ok with only one input as long as default value for mealState is "UNKNOWN" when creating the biometric
		*/

		const hasDateOn = Object.hasOwn(formData, "dateOn") && !!formData["dateOn"];

		const hasOneBiometric = Object.keys(formData).some((key) => {
			const isBiometric = !["dateOn", "estimated", "glucoseContext"].includes(key);
			const hasValue = !!(formData as any)[key];
			return isBiometric && hasValue;
		});

		return hasDateOn && hasOneBiometric;
	};

	const handleSubmit = async (data: IBiometricData) => {
		if (isValid()) {
			setMessage(null);

			await EndpointsService.dataRetriever
				.createBiometric({
					body: BiometricsHelper.setCreateBody(formData, participantId),
				})
				.then(() => onComplete())
				.catch(() => setMessage("UI_COMPONENTS.FIELD_MESSAGE.HTTP.500"));
		} else {
			setMessage("PARTICIPANT.BIOMETRICS.CREATE.FORM_INVALID");
		}
	};

	return (
		<Dialog
			header={t("PARTICIPANT.BIOMETRICS.TITLE")}
			visible={isVisible}
			className="biometrics-modal"
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: "80vw" }}
			modal={true}
			focusOnShow={false}
			onHide={onClose}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<FormSection className="biometrics-form">
							<div className="row">
								{/* Date taken */}
								<div className="col-12 col-md-6 col-lg-3 col-xl-3">
									<UiInputDate
										id="dateOn"
										placeholder="ENUMS.BIOMETRICS.dateOn"
										label="ENUMS.BIOMETRICS.dateOn"
										name="dateOn"
										onChange={(e) =>
											setFormData({
												...formData,
												dateOn: (e.target.value as Date) || null,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
										showTime
										maxDate={new Date()}
										showButtonBar={false}
									/>
								</div>

								{/* Estimated date */}
								<div className="col-12 col-md-6 col-lg-7 col-xl-4">
									<UiInputCheckbox
										id="estimated"
										name="estimated"
										label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.estimated"
										value={formData.estimated}
										onChange={(e) =>
											setFormData({ ...formData, estimated: e.target.value })
										}
										disabled={submitting}
										binary={true}
									/>
								</div>
							</div>

							<div className="row">
								{/* Hemoglobine A1C */}
								<div className="col-12 col-md-6 col-lg-2 col-xl-2">
									<div className="key">{t("ENUMS.BIOMETRICS.hemoglobinA1c")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="hemoglobinA1c"
											placeholder="00.0"
											name="hemoglobinA1c"
											className="input-width field-3"
											onValueChange={(e) =>
												setFormData({ ...formData, hemoglobinA1c: e.value })
											}
											disabled={submitting}
											max={99.9}
											size={1}
											maxFractionDigits={1}
										/>
										<span className="unit">{BiometricUnit.hemoglobinA1c}</span>
									</div>
								</div>

								{/* Blood pressure */}
								<div className="col-12 col-md-6 col-lg-4 col-xl-3">
									<div className="key">{t("ENUMS.BIOMETRICS.bloodPressure")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="bloodPressureSystolic"
											placeholder="000"
											name="bloodPressureSystolic"
											className="input-width field-3"
											onValueChange={(e) =>
												setFormData({
													...formData,
													bloodPressureSystolic: e.value,
												})
											}
											disabled={submitting}
											max={999}
										/>

										{/* Separator */}
										<span className="input-separator">/</span>

										<UiInputNumber
											id="bloodPressureDiastolic"
											placeholder="000"
											name="bloodPressureDiastolic"
											className="input-width field-3"
											onValueChange={(e) =>
												setFormData({
													...formData,
													bloodPressureDiastolic: e.value,
												})
											}
											disabled={submitting}
											max={999}
										/>
										<span className="unit">{BiometricUnit.bloodPressure}</span>
									</div>
								</div>

								{/* Glucose */}
								<div className="col-12 col-md-6 col-lg-6 col-xl-4">
									<div className="key">{t("ENUMS.BIOMETRICS.glucose")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="glucoseValue"
											placeholder="000"
											name="glucoseValue"
											className="input-width field-3"
											onValueChange={(e) =>
												setFormData({ ...formData, glucoseValue: e.value })
											}
											disabled={submitting}
											max={999}
										/>
										<span className="unit input-separator">
											{BiometricUnit.cholesterol}
										</span>

										<UiSelect
											id="glucoseContext"
											name="glucoseContext"
											placeholder="PARTICIPANT.BIOMETRICS.CREATE.MEAL_STATE_PLACEHOLDER"
											disabled={submitting}
											onChange={(e) =>
												setFormData({
													...formData,
													glucoseContext: e.target.value,
												})
											}
											options={[
												{
													id: "PREMEAL",
													label: "ENUMS.MEAL_STATE.PREMEAL",
												},
												{
													id: "POSTMEAL",
													label: "ENUMS.MEAL_STATE.POSTMEAL",
												},
												{
													id: "FASTING",
													label: "ENUMS.MEAL_STATE.FASTING",
												},
												{
													id: "UNKNOWN",
													label: "ENUMS.MEAL_STATE.UNKNOWN",
												},
											]}
										/>
									</div>
								</div>

								{/* Weight */}
								<div className="col-12 col-md-6 col-lg-3 col-xl-2">
									<div className="key">{t("ENUMS.BIOMETRICS.weight")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="weight"
											placeholder="000.0"
											name="weight"
											className="input-width field-4"
											onValueChange={(e) =>
												setFormData({ ...formData, weight: e.value })
											}
											disabled={submitting}
											max={999.9}
											maxFractionDigits={1}
										/>
										<span className="unit">{BiometricUnit.weight}</span>
									</div>
								</div>

								{/* Height */}
								<div className="col-12 col-md-6 col-lg-3 col-xl-2">
									<div className="key">{t("ENUMS.BIOMETRICS.height")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="heightFeet"
											placeholder="00"
											name="heightFeet"
											className="input-width field-2"
											onValueChange={(e) =>
												setFormData({ ...formData, heightFeet: e.value })
											}
											disabled={submitting}
										/>
										<span className="unit top input-separator">
											{BiometricUnit.heightFeet}
										</span>

										<UiInputNumber
											id="heightInches"
											placeholder="00"
											name="heightInches"
											className="input-width field-2"
											onValueChange={(e) =>
												setFormData({ ...formData, heightInches: e.value })
											}
											disabled={submitting}
										/>
										<span className="unit top">
											{BiometricUnit.heightInches}
										</span>
									</div>
								</div>

								{/* Triglycerides */}
								<div className="col-12 col-md-6 col-lg-3 col-xl-3">
									<div className="key">{t("ENUMS.BIOMETRICS.triglycerides")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="triglycerides"
											placeholder="00000"
											name="triglycerides"
											className="input-width field-5"
											onValueChange={(e) =>
												setFormData({ ...formData, triglycerides: e.value })
											}
											disabled={submitting}
											max={99999}
										/>
										<span className="unit">{BiometricUnit.triglycerides}</span>
									</div>
								</div>

								{/* Kidney GFR */}
								<div className="col-12 col-md-6 col-lg-4 col-xl-3">
									<div className="key">{t("ENUMS.BIOMETRICS.kidneyGfr")}</div>
									<div className="input-unit">
										<UiInputNumber
											id="kidneyGfr"
											placeholder="000.0"
											name="kidneyGfr"
											className="input-width field-4"
											onValueChange={(e) =>
												setFormData({ ...formData, kidneyGfr: e.value })
											}
											disabled={submitting}
											max={999.9}
											maxFractionDigits={1}
										/>
										<span className="unit">{BiometricUnit.kidneyGfr}</span>
									</div>
								</div>

								{/* Cholesterol */}
								<div className="col-12 col-md-12 col-lg-9 col-xl-6">
									<div className="key">{t("ENUMS.BIOMETRICS.cholesterol")}</div>
									<div className="row">
										{/* Cholesterol HDL */}
										<div className="col-12 col-md-4 col-lg-4">
											<div className="input-unit">
												<div className="unit">
													{t("ENUMS.BIOMETRICS.cholesterolHdl")}
												</div>
												<UiInputNumber
													id="cholesterolHdl"
													placeholder="000"
													name="cholesterolHdl"
													className="input-width field-3 mx-2"
													onValueChange={(e) =>
														setFormData({
															...formData,
															cholesterolHdl: e.value,
														})
													}
													disabled={submitting}
													max={999}
												/>
												<span className="unit">
													{BiometricUnit.cholesterol}
												</span>
											</div>
										</div>

										{/* Cholesterol LDL */}
										<div className="col-12 col-md-4 col-lg-4">
											<div className="input-unit">
												<div className="unit">
													{t("ENUMS.BIOMETRICS.cholesterolLdl")}
												</div>
												<UiInputNumber
													id="cholesterolLdl"
													placeholder="000"
													name="cholesterolLdl"
													className="input-width field-3 mx-2"
													onValueChange={(e) =>
														setFormData({
															...formData,
															cholesterolLdl: e.value,
														})
													}
													disabled={submitting}
													max={999}
												/>
												<span className="unit">
													{BiometricUnit.cholesterol}
												</span>
											</div>
										</div>

										{/* Cholesterol TOTAL */}
										<div className="col-12 col-md-4 col-lg-4">
											<div className="input-unit">
												<div className="unit">
													{t("ENUMS.BIOMETRICS.cholesterolTotal")}
												</div>
												<UiInputNumber
													id="cholesterolTotal"
													placeholder="000"
													name="cholesterolTotal"
													className="input-width field-3 mx-2"
													onValueChange={(e) =>
														setFormData({
															...formData,
															cholesterolTotal: e.value,
														})
													}
													disabled={submitting}
													max={999}
												/>
												<span className="unit">
													{BiometricUnit.cholesterol}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FormSection>

						{message && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label={message}
								/>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								label="UI_COMPONENTS.BUTTONS.CANCEL"
								type="button"
								className="p-button-outlined p-button-rounded"
								onClick={onClose}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "UI_COMPONENTS.BUTTONS.SAVING"
										: "UI_COMPONENTS.BUTTONS.SAVE"
								}
								className="p-button-rounded"
								type="submit"
								loading={submitting}
								disabled={submitting}
								onClick={handleSubmit}
							/>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};
export default BiometricsCreate;
