import UiButton from "../../components/button/Button";
import { BiometricType, BiometricUnit, Biometric } from "../../models/entities/biometrics.model";
import { Formatter } from "../formatter/formatter.service";
import { BiometricsFormatter } from "./biometrics-formatter.service";
import UiInputDate from "../../components/input-date/InputDate.component";
import { Validations } from "../form/validations.service";
import { BiometricRow } from "../../models/pages/biometrics/biometrics-modal.model";
import {
	BiometricValueTemplateBloodPressure,
	BiometricValueTemplateCholesterol,
	BiometricValueTemplateHeight,
	BiometricValueTemplateHemoglobinA1c,
	BiometricValueTemplateKidneyGfr,
	BiometricValueTemplateWeight,
} from "../../models/pages/biometrics/biometrics-templates.model";
import { Source } from "../../models/entities/source.model";
import UiInputNumber from "../../components/input-number/InputNumber.component";
import UiSelect from "../../components/select/Select.component";
import { useTranslation } from "react-i18next";
import { Goal } from "../../models/entities/goal.model";

/**
 * ROWS
 */

// Row: Hemoglobin A1C
const hemoglobinA1c = (data: Biometric | null, goals: Goal[]): BiometricRow => {
	const entry = data?.entries.filter((x) => x.subType === BiometricType.hemoglobin_a1c)?.[0];
	const value: BiometricValueTemplateHemoglobinA1c = entry?.value ?? null;
	const rowGoals = goals.filter((goal) => goal.goalName.startsWith("A1C"));
	return {
		ids: entry ? [entry?.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.hemoglobin_a1c,
		measurement: "ENUMS.BIOMETRICS.hemoglobinA1c",
		value: {
			formatted: BiometricsFormatter.formatHemoglobinA1c(entry?.value),
			value,
		},
		goal: rowGoals.length > 0 ? rowGoals[0] : undefined,
		estimated: data?.estimated ?? false,
		dateOn: {
			formatted: data?.vitalTime
				? (Formatter.date(data.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: data?.vitalTime ?? null,
		},
		source: {
			formatted: data?.source ? `ENUMS.SOURCE.${data.source}` : Formatter.NO_DATA,
			value: data?.source ?? null,
		},
		notes: {
			formatted: data?.notes ?? Formatter.NO_DATA,
			value: data?.notes ?? null,
		},
		editing: false,
	};
};

// Row: Blood pressure
const bloodPressure = (bloodPressure: Biometric | null, goals: Goal[]): BiometricRow => {
	const bloodPressureDiastolic = bloodPressure?.entries.filter(
		(x) => x.subType === BiometricType.blood_pressure_diastolic
	)?.[0];
	const bloodPressureSystolic = bloodPressure?.entries.filter(
		(x) => x.subType === BiometricType.blood_pressure_systolic
	)?.[0];

	const value: BiometricValueTemplateBloodPressure = {
		bloodPressureDiastolic: bloodPressureDiastolic?.value ?? null,
		bloodPressureSystolic: bloodPressureSystolic?.value ?? null,
	};

	const rowGoals = goals.filter((goal) => goal.goalName.startsWith("BPS"));

	const ids = [];
	if (bloodPressureSystolic?.biometricId) ids.push(bloodPressureSystolic?.biometricId);
	if (bloodPressureDiastolic?.biometricId) ids.push(bloodPressureDiastolic?.biometricId);

	const subTypes = [];
	if (bloodPressureSystolic) subTypes.push(bloodPressureSystolic?.subType as BiometricType);
	if (bloodPressureDiastolic) subTypes.push(bloodPressureDiastolic?.subType as BiometricType);

	return {
		ids,
		subTypes,
		type: BiometricType.blood_pressure,
		measurement: "ENUMS.BIOMETRICS.bloodPressure",
		goal: rowGoals.length ? rowGoals[0] : undefined,
		value: {
			formatted: BiometricsFormatter.formatBloodPressure(
				bloodPressureSystolic?.value,
				bloodPressureDiastolic?.value
			),
			value,
		},
		estimated: bloodPressure?.estimated ?? false,
		dateOn: {
			formatted: bloodPressure?.vitalTime
				? (Formatter.date(bloodPressure?.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: bloodPressure?.vitalTime ?? null,
		},
		source: {
			formatted: bloodPressure?.source
				? `ENUMS.SOURCE.${bloodPressure.source}`
				: Formatter.NO_DATA,
			value: bloodPressure?.source ?? null,
		},
		notes: {
			formatted: bloodPressure?.notes ?? Formatter.NO_DATA,
			value: bloodPressure?.notes ?? null,
		},
		editing: false,
	};
};

// Row: Glucose
const glucose = (glucose: Biometric | null) => {
	const entry = glucose?.entries.filter((x) => x.subType === BiometricType.glucose)?.[0];

	return {
		ids: entry ? [entry?.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.glucose,
		measurement: "ENUMS.BIOMETRICS.glucose",
		value: {
			formatted: BiometricsFormatter.formatGlucose(entry?.value as any),
			value: {
				glucoseValue: entry?.value ?? null,
				glucoseContext: getGlucoseTypeFromInfo({
					isFasting: glucose?.isFasting ?? null,
					isPreMeal: glucose?.isPreMeal ?? null,
				}),
			},
		},
		estimated: glucose?.estimated ?? false,
		dateOn: {
			formatted: glucose?.vitalTime
				? (Formatter.date(glucose?.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: glucose?.vitalTime ?? null,
		},
		source: {
			formatted: glucose?.source ? `ENUMS.SOURCE.${glucose.source}` : Formatter.NO_DATA,
			value: glucose?.source ?? null,
		},
		notes: {
			formatted: glucose?.notes ?? Formatter.NO_DATA,
			value: glucose?.notes ?? null,
		},
		editing: false,
	};
};

// Row: Height
const height = (data: Biometric | null): BiometricRow => {
	const entry = data?.entries.filter((x) => x.subType === BiometricType.height)?.[0];
	const value: BiometricValueTemplateHeight = BiometricsFormatter.formatHeightValues(
		entry?.value ?? null
	);

	return {
		ids: data && entry ? [entry?.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.height,
		measurement: "ENUMS.BIOMETRICS.height",
		value: {
			formatted: BiometricsFormatter.formatHeight(entry?.value),
			value,
		},
		estimated: data?.estimated ?? false,
		dateOn: {
			formatted: data?.vitalTime
				? (Formatter.date(data.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: data?.vitalTime as string,
		},
		source: {
			formatted: data?.source ? `ENUMS.SOURCE.${data?.source}` : Formatter.NO_DATA,
			value: data?.source ?? null,
		},
		notes: {
			formatted: data?.notes ?? Formatter.NO_DATA,
			value: data?.notes ?? null,
		},
		editing: false,
	};
};

// Row: Weight
const weight = (data: Biometric | null): BiometricRow => {
	const entry = data?.entries.filter((x) => x.subType === BiometricType.weight)?.[0];
	const value: BiometricValueTemplateWeight = entry?.value ?? null;
	return {
		ids: data && entry ? [entry.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.weight,
		measurement: "ENUMS.BIOMETRICS.weight",
		value: {
			formatted: BiometricsFormatter.formatWeight(entry?.value),
			value,
		},
		estimated: data?.estimated ?? false,
		dateOn: {
			formatted: data?.vitalTime
				? (Formatter.date(data.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: data?.vitalTime as string,
		},
		source: {
			formatted: data?.source ? `ENUMS.SOURCE.${data.source}` : Formatter.NO_DATA,
			value: data?.source ?? null,
		},
		notes: {
			formatted: data?.notes ?? Formatter.NO_DATA,
			value: data?.notes ?? null,
		},
		editing: false,
	};
};

// // Row: Cholesterol
const cholesterol = (cholesterol: Biometric | null): BiometricRow => {
	const cholesterolHdl = cholesterol?.entries.filter(
		(x) => x.subType === BiometricType.cholesterol_hdl
	)?.[0];
	const cholesterolLdl = cholesterol?.entries.filter(
		(x) => x.subType === BiometricType.cholesterol_ldl
	)?.[0];
	const cholesterolTotal = cholesterol?.entries.filter(
		(x) => x.subType === BiometricType.cholesterol_total
	)?.[0];

	const ids = [];
	if (cholesterolHdl?.biometricId) ids.push(cholesterolHdl?.biometricId);
	if (cholesterolLdl?.biometricId) ids.push(cholesterolLdl?.biometricId);
	if (cholesterolTotal?.biometricId) ids.push(cholesterolTotal?.biometricId);

	const subTypes = [];
	if (cholesterolHdl?.subType) subTypes.push(cholesterolHdl?.subType as BiometricType);
	if (cholesterolLdl?.subType) subTypes.push(cholesterolLdl?.subType as BiometricType);
	if (cholesterolTotal?.subType) subTypes.push(cholesterolTotal?.subType as BiometricType);

	const value: BiometricValueTemplateCholesterol = {
		cholesterolHdl: cholesterolHdl?.value ?? null,
		cholesterolLdl: cholesterolLdl?.value ?? null,
		cholesterolTotal: cholesterolTotal?.value ?? null,
	};

	return {
		ids: ids,
		subTypes: subTypes,
		type: BiometricType.cholesterol,
		measurement: "ENUMS.BIOMETRICS.cholesterol",
		value: {
			formatted: BiometricsFormatter.formatCholesterol(
				cholesterolHdl?.value,
				cholesterolLdl?.value,
				cholesterolTotal?.value
			),
			value,
		},
		estimated: cholesterol?.estimated ?? false,
		dateOn: {
			formatted: cholesterol?.vitalTime
				? (Formatter.date(cholesterol.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: cholesterol?.vitalTime as string,
		},
		source: {
			formatted: cholesterol?.source
				? `ENUMS.SOURCE.${cholesterol?.source}`
				: Formatter.NO_DATA,
			value: cholesterol?.source ?? null,
		},
		notes: {
			formatted: cholesterol?.notes ?? Formatter.NO_DATA,
			value: cholesterol?.notes ?? null,
		},
		editing: false,
	};
};

// // Row: Triglycerides
const triglycerides = (data: Biometric | null): BiometricRow => {
	const entry = data?.entries.filter((x) => x.subType === BiometricType.triglycerides)?.[0];
	const value: BiometricValueTemplateWeight = entry?.value ?? null;
	return {
		ids: entry ? [entry?.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.triglycerides,
		measurement: "ENUMS.BIOMETRICS.triglycerides",
		value: {
			formatted: BiometricsFormatter.formatTriglycerides(entry?.value),
			value,
		},
		estimated: data?.estimated ?? false,
		dateOn: {
			formatted: data?.vitalTime
				? (Formatter.date(data?.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: data?.vitalTime as string,
		},
		source: {
			formatted: data?.source ? `ENUMS.SOURCE.${data?.source}` : Formatter.NO_DATA,
			value: data?.source ?? null,
		},
		notes: {
			formatted: data?.notes ?? Formatter.NO_DATA,
			value: data?.notes ?? null,
		},
		editing: false,
	};
};

// // Row: Kidney GFR
const kidneyGfr = (data: Biometric | null): BiometricRow => {
	const entry = data?.entries.filter((x) => x.subType === BiometricType.kidney_gfr)?.[0];
	const value: BiometricValueTemplateKidneyGfr = entry?.value ?? null;
	return {
		ids: entry ? [entry.biometricId] : null,
		subTypes: [entry?.subType as BiometricType],
		type: BiometricType.kidney_gfr,
		measurement: "ENUMS.BIOMETRICS.kidneyGfr",
		value: {
			formatted: BiometricsFormatter.formatKidneyGfr(entry?.value),
			value,
		},
		estimated: data?.estimated ?? false,
		dateOn: {
			formatted: data?.vitalTime
				? (Formatter.date(data.vitalTime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					}) as string)
				: Formatter.NO_DATA,
			value: data?.vitalTime as string,
		},
		source: {
			formatted: data?.source ? `ENUMS.SOURCE.${data?.source}` : Formatter.NO_DATA,
			value: data?.source ?? null,
		},
		notes: {
			formatted: data?.notes ?? Formatter.NO_DATA,
			value: data?.notes ?? null,
		},
		editing: false,
	};
};

/**
 * COLUMNS
 */

// Column: date taken
const templateDateTaken = (
	row: BiometricRow,
	updateFn: (value: Date | null) => void,
	submitting: boolean
): React.ReactNode => {
	let template: React.ReactNode;
	if (!row.editing) {
		template = <span className="text">{row.dateOn.formatted}</span>;
	} else {
		template = (
			<UiInputDate
				id="dateOn"
				placeholder="ENUMS.BIOMETRICS.dateOn"
				name="dateOn"
				onChange={(e) => updateFn(e.target.value as Date | null)}
				validations={[Validations.required]}
				disabled={submitting}
				view="month"
				dateFormat="mm/yy"
				showButtonBar={false}
				maxDate={new Date()}
				panelClassName="month-year-table"
				// appendTo='self'
			/>
		);
	}
	return template;
};

// Template: source
const templateSource = (formattedValue: string): React.ReactNode => (
	<span className="text">{formattedValue}</span>
);

// Template: notes
const templateNotes = (notes: string | null): React.ReactNode => (
	<span className="text">{notes ?? Formatter.NO_DATA}</span>
);

// Template: value
const templateValue = (
	row: BiometricRow,
	updateFn: (value: any) => void,
	submitting: boolean,
	value: any = undefined
): React.ReactNode => {
	const { t } = useTranslation("common"); // eslint-disable-line

	let template: React.ReactNode;

	if (!row.editing) {
		template = (
			<>
				{row.value.formatted.split(",").map((item, i) => {
					const value = item.trim();

					const shouldShowGlucoseMealState =
						row.type === BiometricType.glucose &&
						(row.value.value as any).glucoseContext !== "UNKNOWN";

					const glucoseMealState = shouldShowGlucoseMealState
						? t(`ENUMS.MEAL_STATE.${(row.value.value as any).glucoseContext}`)
						: "";

					return (
						<span
							className="text"
							key={i}
						>
							{value}
							{shouldShowGlucoseMealState ? ` ${glucoseMealState}` : ""}
							<br />
						</span>
					);
				})}
			</>
		);
	} else {
		// Update mode
		switch (row.type) {
			case BiometricType.hemoglobin_a1c:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value"
							placeholder="00.0"
							name="value"
							value={value}
							className="input-width field-3"
							onValueChange={(e) => updateFn(e.value)}
							disabled={submitting}
							max={99.9}
							maxFractionDigits={1}
						/>
						<span className="ms-2">{BiometricUnit.hemoglobinA1c}</span>
					</div>
				);
				break;
			case BiometricType.blood_pressure:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value.bloodPressureSystolic"
							placeholder="000"
							name="value.bloodPressureSystolic"
							className="input-width field-3"
							value={value.bloodPressureSystolic}
							onValueChange={(e) => updateFn({ bloodPressureSystolic: e.value })}
							disabled={submitting}
							max={999}
						/>

						{/* Separator */}
						<span className="input-separator mb-0">/</span>

						<UiInputNumber
							id="value.bloodPressureDiastolic"
							placeholder="000"
							name="value.bloodPressureDiastolic"
							className="input-width field-3"
							value={value.bloodPressureDiastolic}
							onValueChange={(e) => updateFn({ bloodPressureDiastolic: e.value })}
							disabled={submitting}
							max={999}
						/>
						<span className="ms-2">{BiometricUnit.bloodPressure}</span>
					</div>
				);
				break;
			case BiometricType.glucose:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value.glucoseValue"
							placeholder="000"
							name="value.glucoseValue"
							className="input-width field-3"
							onValueChange={(e) => updateFn({ glucoseValue: e.value })}
							value={value.glucoseValue}
							disabled={submitting}
							max={999}
						/>
						<span className="input-separator mb-0">{BiometricUnit.cholesterol}</span>

						<UiSelect
							id="value.glucoseContext"
							name="value.glucoseContext"
							placeholder="PARTICIPANT.BIOMETRICS.CREATE.MEAL_STATE_PLACEHOLDER"
							value={t(`ENUMS.MEAL_STATE.${value.glucoseContext}`)}
							onChange={(e) => updateFn({ glucoseContext: e.value })}
							disabled={submitting}
							options={[
								{
									id: "PREMEAL",
									label: "ENUMS.MEAL_STATE.PREMEAL",
								},
								{
									id: "POSTMEAL",
									label: "ENUMS.MEAL_STATE.POSTMEAL",
								},
								{
									id: "FASTING",
									label: "ENUMS.MEAL_STATE.FASTING",
								},
								{
									id: "UNKNOWN",
									label: "ENUMS.MEAL_STATE.UNKNOWN",
								},
							]}
						/>
					</div>
				);
				break;
			case BiometricType.weight:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value"
							placeholder="000.0"
							name="value"
							className="input-width field-4"
							onValueChange={(e) => updateFn(e.value)}
							value={value}
							disabled={submitting}
							max={999.9}
							maxFractionDigits={1}
						/>
						<span className="ms-2">{BiometricUnit.weight}</span>
					</div>
				);
				break;
			case BiometricType.height:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value.heightFeet"
							placeholder="00"
							name="value.heightFeet"
							className="input-width field-2"
							onValueChange={(e) => updateFn({ heightFeet: e.value })}
							value={value.heightFeet}
							disabled={submitting}
						/>
						<span className="unit me-2 top">{BiometricUnit.heightFeet}</span>

						<UiInputNumber
							id="value.heightInches"
							placeholder="00"
							name="value.heightInches"
							value={value.heightInches}
							className="input-width field-2"
							onValueChange={(e) => updateFn({ heightInches: e.value })}
							disabled={submitting}
						/>
						<span className="unit top">{BiometricUnit.heightInches}</span>
					</div>
				);
				break;
			case BiometricType.cholesterol:
				template = (
					<div className="d-flex flex-column align-items-end">
						<div className="input-unit">
							<span className="me-2">HDL</span>
							<UiInputNumber
								id="value.cholesterolHdl"
								placeholder="000"
								name="value.cholesterolHdl"
								value={value.cholesterolHdl}
								className="input-width field-3"
								onValueChange={(e) => updateFn({ cholesterolHdl: e.value })}
								disabled={submitting}
								max={999}
							/>
							<span className="ms-2">{BiometricUnit.cholesterol}</span>
						</div>
						<div className="input-unit">
							<span className="me-2">LDL</span>
							<UiInputNumber
								id="value.cholesterolLdl"
								placeholder="000"
								name="value.cholesterolLdl"
								value={value.cholesterolLdl}
								className="input-width field-3"
								onValueChange={(e) => updateFn({ cholesterolLdl: e.value })}
								disabled={submitting}
								max={999}
							/>
							<span className="ms-2">{BiometricUnit.cholesterol}</span>
						</div>
						<div className="input-unit">
							<span className="me-2">Total</span>
							<UiInputNumber
								id="value.cholesterolTotal"
								placeholder="000"
								name="value.cholesterolTotal"
								value={value.cholesterolTotal}
								className="input-width field-3"
								onValueChange={(e) => updateFn({ cholesterolTotal: e.value })}
								disabled={submitting}
								max={999}
							/>
							<span className="ms-2">{BiometricUnit.cholesterol}</span>
						</div>
					</div>
				);

				break;
			case BiometricType.triglycerides:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value"
							placeholder="00000"
							name="value"
							className="input-width field-5"
							onValueChange={(e) => updateFn(e.value)}
							value={value}
							disabled={submitting}
							max={99999}
						/>
						<span className="ms-2">{BiometricUnit.triglycerides}</span>
					</div>
				);
				break;
			case BiometricType.kidney_gfr:
				template = (
					<div className="input-unit">
						<UiInputNumber
							id="value"
							placeholder="000.0"
							name="value"
							className="input-width field-4"
							onValueChange={(e) => updateFn(e.value)}
							disabled={submitting}
							value={value}
							max={999.9}
							maxFractionDigits={1}
						/>
						<span className="ms-2">{BiometricUnit.kidneyGfr}</span>
					</div>
				);
				break;
			default:
				break;
		}
	}

	return template;
};

// Template: Actions (Edit and Delete)
const templateActions = (
	row: BiometricRow,
	editableFn: (row: BiometricRow) => void,
	cancelFn: (row: BiometricRow) => void,
	submitting: boolean,
	deleteFn: (row: BiometricRow) => void
): React.ReactNode => {
	const isBiometricEmpty = row.source.value === null;
	const isBiometricFromExternalSource =
		row.source.value === Source.CLAIMS || row.source.value === Source.QHIN;
	const isBiometricFromDevice = row.source.value === Source.DEVICE;
	const isEditingBiometric = row.editing;

	if (isBiometricEmpty || isBiometricFromExternalSource) {
		return null;
	}

	if (isEditingBiometric) {
		return (
			<>
				<UiButton
					type="button"
					className="p-button-sm p-button-outlined p-button-rounded"
					label="UI_COMPONENTS.BUTTONS.CANCEL"
					onClick={cancelFn}
					disabled={submitting}
				/>
				<UiButton
					type="submit"
					className="p-button-sm p-button-rounded"
					label={
						submitting ? "UI_COMPONENTS.BUTTONS.SAVING" : "UI_COMPONENTS.BUTTONS.SAVE"
					}
					loading={submitting}
					disabled={submitting}
				/>
			</>
		);
	}

	// We want to allow coaches to delete readings that come from a device but not edit them.
	//  eg of scenario: participant partner uses participant weight scale and a reading is reported,
	//    in this scenario we want the coach to be able to delete the reading.

	// Implementation detail: We're rendering a non clickable invisible edit button alongside the trash icon
	//  to ensure that the delete button is positioned correctly in the layout.
	return (
		<>
			<UiButton
				key="edit-biometric"
				type="button"
				icon="pi pi-pencil"
				className="p-button-text neutral"
				onClick={isBiometricFromDevice ? undefined : () => editableFn(row)}
				style={{
					marginRight: 0,
					...(isBiometricFromDevice ? { cursor: "auto", opacity: 0 } : {}),
				}}
			/>
			<UiButton
				type="button"
				key="delete-biometric"
				icon="pi pi-trash"
				className="p-button-text neutral"
				onClick={() => deleteFn(row)}
			/>
		</>
	);
};

const getGlucoseTypeFromInfo = (info: {
	isPreMeal: boolean | null;
	isFasting: boolean | null;
}): "PREMEAL" | "FASTING" | "POSTMEAL" | "UNKNOWN" => {
	if (info.isFasting) return "FASTING";
	if (info.isPreMeal) return "PREMEAL";
	if (info.isPreMeal === false && info.isFasting === false) return "POSTMEAL";
	return "UNKNOWN";
};

/**
 * COLUMNS WIDTH
 */

const columnsWidth = {
	measurement: "160px",
	value: "150px",
	goal: "250px",
	dateOn: "220px",
	source: "100px",
	notes: "150px",
	expandCollapse: "150px",
	edit: "150px",
};

export const BiometricsTemplate = {
	// Rows
	hemoglobinA1c,
	bloodPressure,
	glucose,
	height,
	weight,
	cholesterol,
	triglycerides,
	kidneyGfr,
	// Column templates
	templateDateTaken,
	templateSource,
	templateNotes,
	templateValue,
	templateActions,
	// Columns width
	columnsWidth,
};
